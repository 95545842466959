import { Button, notification, Spin } from "antd";
import * as React from "react";
import { Input, Layout } from "antd";
import { Field, Form, Formik } from "formik";
import { findByEmail } from "../../Graphs/Employee/searchEmployee";
import { alertError } from "../../utils/alert";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
  fnameValidation,
  lnameValidation,
  passwordValidation,
} from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import { StyleSelect } from "../../assets/css/style";

const { Content } = Layout;
export class AddDistributorForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      errors: {},
    };
  }
  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  validateEmail = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });
      let getEmployee = await findByEmail(this.props.authToken, value);
      this.setState({
        isLoading: false,
      });

      if (getEmployee.status == 200) {
        if (getEmployee.data == null) {
          return true;
        } else {
          notification["warn"]({
            message: "Email Validation",
            description: "Email is already in used",
          });
          return false;
        }
      } else {
        throw getEmployee.message ? getEmployee.message : "Error";
        return -1;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("DISTRIBUTOR", error);

      return -1;
    }
  };

  validateForm(values) {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    } else if(fnameValidation(values.first_name)) {
      errors.first_name = errorMessages.fname_capital;
    }

    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    } else if(lnameValidation(values.last_name)) {
      errors.last_name = errorMessages.lname_capital;
    }

    if (!values.email) {
      errors.email = errorMessages.email_required;
    } else if (emailValidation(values.email)) {
      errors.email = errorMessages.email_validation;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    return errors;
  }

  async handleSubmit(values, action) {
    console.log("grree");
    let listErrors = this.state.errors;

    let checkEmail = await this.validateEmail(values.email);

    if (checkEmail == false || checkEmail == -1) {
      if (checkEmail == false) {
        listErrors.email = "Email is already in used.";
        this.setState({
          errors: listErrors,
        });
        return;
      }
      return;
    }

    this.setState({
      errors: {},
    });

    let result = await this.props.onSubmit(values);

    if (result) {
      this.props.handleCancel();
      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                password: "",
                commission: "",
                city: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <Form>
                    {this.state.isLoading ? (
                      <div style={{ justifyContent: "center" }}>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            First Name
                            <Input
                              id="first_name"
                              placeholder="First Name"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.first_name && touched.first_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.first_name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Last Name
                            <Input
                              id="last_name"
                              placeholder="Last Name"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.last_name && touched.last_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.last_name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Phone
                            <Input
                              id="phone"
                              placeholder="User Phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.phone}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Email
                            <Input
                              id="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.email}
                              </p>
                            ) : null}
                            {this.state.errors.email ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {this.state.errors.email}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Percentage
                            <Input
                              id="commission"
                              type="commission"
                              placeholder="Commision"
                              value={values.commission}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>

                          <div className="formik-field-right">
                            Password
                            <Input
                              id="password"
                              type="password"
                              placeholder="User Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.password}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="formik-field_wrap">

                        {/* {this.props?.loginUserData?.role != "Sub_admin" && (
                        <div className="formik-field-left">
                        City
                        <Field
                          name="cityName"
                          render={({ field }) => (
                            <StyleSelect
                              placeholder="Select City"
                              style={{ width: "100%" }}
                              name="cityName"
                              {...field}
                              onBlur={() => setFieldTouched("cityName", true)}
                              onSelect={(value, option) => {
                                setFieldValue("cityName", value);
                                setFieldValue("city", option.id);
                              }}
                              options={this.props.cityData}
                            />
                          )}
                        />
                        </div>
                        )} */}

                    </div>
                        <Button
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
