import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logo_compact from '../../../assets/images/logo/compact-logo.png';
import UserPanel from './userPanel';
import { MENUITEMS, distributorMenuItems } from '../../../constant/menu';
import { Link, withRouter } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import configDB from '../../../data/customizer/config';
import { IdcardOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { camelCase, lowerCase } from 'lodash';
import { getDistributorBalance } from '../../../Graphs/distributor/showDistributorBalance';
import { useLocation } from 'react-router-dom';

const filterMenuItems = (menuItems, permission) => {
    return menuItems
      .map((item) => {
  
        if (item.type === "sub") {
          if (item.children) {
            const filteredChildren = filterMenuItems(item.children, permission);
            return { ...item, children: filteredChildren };
          }
          return item;
        }
        const key = camelCase(item.title.replace(/_/g, "")).split(" ").join("");
        const hasPermission = permission[key]?.view;
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children, permission);
          if (filteredChildren.length > 0 || hasPermission) {
            return { ...item, children: filteredChildren };
          }
        } else if (hasPermission) {
          return item;
        }
  
        return null;
      })
      .filter(Boolean);
  };

const Sidebar = (props) => {
    const location = useLocation();
    const currentRoute = location.pathname; 
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const wrapper = configDB.data.settings.sidebar.wrapper;
    const layout = useSelector(content => content.Customizer.layout);
    const adminName = useSelector(content => content.auth.loginUserData ? content.auth.loginUserData.first_name : "ADMIN");
    const role = useSelector(content => content.auth.loginUserData ? content.auth.loginUserData.role : "");
    const [employeeAccountantData, setEmployeeAccountantData] = useState([]);
    const loginUserData = props.loginUserData;
    const authToken = props.authToken;
    const [distributorAmount , setDistributorAmount] = useState(0);

    const permission = loginUserData != null ? (loginUserData.access_permission != null && loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) : {}      
    if (loginUserData != null ) {
            if (permission?.user?.view) {
            permission.profitWalletRequests = {
                view: true,
                edit: true,
                delete: true
            };
        }
    }  
    // useEffect(() => {
    //     let menu = MENUITEMS.reduce((a, v) => ({ ...a, [camelCase(v.title).split(" ").join("")]: v }), {})
    //     for (const [key, value] of Object.entries(permission)) {
    //         if (value.view) {
    //             employeeAccountantData.push(menu[key]);
    //         }
    //     }
    // }, [])  
    useEffect(() => {
        const filtered = filterMenuItems(MENUITEMS, permission);
        setEmployeeAccountantData(filtered);
      }, []);
    
    const getDistributorAmount = async() => {
        // const {setDistributorBalance} = this.props;
        let balanceData = await getDistributorBalance(authToken);
        if(balanceData){
            // setDistributorBalance(parseFloat(balanceData.data.total_balance).toFixed(2));
            setDistributorAmount(parseFloat(balanceData.data.total_balance).toFixed(2))
        }
    }

    useEffect(() => {
        if(loginUserData && loginUserData.role ===  "Distributor"){
            getDistributorAmount()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize();

        var currentUrl = window.location.pathname;

        // eslint-disable-next-line
        mainmenu.filter(items => {
            if (items.path === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            // eslint-disable-next-line
            items.children.filter(subItems => {
                if (subItems.path === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                // eslint-disable-next-line
                subItems.children.filter(subSubItems => {
                    if (subSubItems.path === currentUrl)
                        setNavActive(subSubItems)
                })
            })
        })

        setTimeout(() => {
            const elmnt = document.getElementById("myDIV");
            if (elmnt != null) {
                const menuWidth = elmnt.offsetWidth;
                // setMenuWidth(menuWidth)
                if (menuWidth > window.innerWidth) {
                    setHideRightArrow(false);
                    setHideLeftArrowRTL(false);
                } else {
                    setHideRightArrow(true);
                    setHideLeftArrowRTL(true);
                }
            }
        }, 500)

        return () => {
            // eslint-disable-next-line
            window.addEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth - 310);
    }

    const setNavActive = (item) => {
        // eslint-disable-next-line
        MENUITEMS.filter(menuItem => {
            // eslint-disable-next-line
            if (menuItem != item)
                menuItem.active = true
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                // eslint-disable-next-line
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }
    const toggletNavActive = (item) => {
        item.active = !item.active;
    
        if (item.children) {
            item.children.forEach(child => {
                child.active = item.active; 
            });
        }
    
        setMainMenu({ mainmenu: MENUITEMS });
    };  

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += (-width));
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }


    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        // Checking condition for remaing margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }
    return (

        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        <Link to="/dashboard" className={currentRoute === '/dashboard' ? 'active' : ''}>
                            <div className="text-center"  >
                                <img src={require("../../../assets/images/logo/point_logo.png")} alt='Logo' style={{ height:"120px" , marginTop:"-30px",display:"block"}} />
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                  <UserPanel adminName={adminName} balance={distributorAmount} />
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? layout === 'rtl' ?
                            { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                        <li className={`left-arrow ${layout === 'rtl' ? hideLeftArrowRTL ? 'd-none' : '' : hideLeftArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToLeftRTL : scrollToLeft}><i className="fa fa-angle-left"></i></li>

                        {loginUserData && loginUserData.role == "Distributor" &&

                            distributorMenuItems.map((menuItem,) => {
                                return (
                                    <li className={`${menuItem.active ? 'active' : ''}`} >
                                        {(menuItem.type === 'link') ?
                                            <Link
                                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                                className={`sidebar-header ${currentRoute === menuItem.path? 'active' : ''}`}
                                                onClick={() => toggletNavActive(menuItem)}
                                            >
                                                {
                                                    menuItem.title === "Card" ? <IdcardOutlined /> : <menuItem.icon />
                                                }
                                                <span>{props.t(menuItem.title )}</span>
                                                {menuItem.children ?
                                                    <i className="fa fa-angle-right pull-right"></i> : ''}
                                            </Link>
                                            : ''}
                                    </li>
                                )
                            })

                        }
                        {loginUserData && (loginUserData.role == "Employee" || loginUserData.role == "Sub_admin" || loginUserData.role == "Accountant" || loginUserData.role == "Sales") ?
                            employeeAccountantData.map((menuItem, index) => {
                                return (
                                    <li className={`${menuItem?.active ? 'active' : ''}`} key={index}>
                                        {(menuItem?.sidebartitle) ?
                                            <div className="sidebar-title">{menuItem?.sidebartitle}</div>
                                            : ''}
                                        {(menuItem?.type === 'sub' && menuItem?.children.length > 0 ) ?
                                            <span className="sidebar_accordion_title"  onClick={() => toggletNavActive(menuItem)}>
                                                {
                                                    menuItem?.title === "Card" ? <IdcardOutlined /> : <menuItem.icon />
                                                }
                                                <span>{props.t(menuItem?.title)}</span>
                                                <i className="fa fa-angle-right pull-right"></i>
                                            </span>
                                            : ''}
                                       {(menuItem?.type === 'link') ?
                                            <Link
                                                to={`${process.env.PUBLIC_URL}${menuItem?.path}`}
                                                className={`sidebar-header ${currentRoute === menuItem?.path ? 'active' : ''}`}

                                                onClick={() => toggletNavActive(menuItem)}
                                            >
                                                {
                                                    menuItem?.title === "Card" ? <IdcardOutlined /> : <menuItem.icon />
                                                }
                                                <span>{props.t(menuItem?.title)}</span>
                                                {menuItem?.children ?
                                                    <i className="fa fa-angle-right pull-right"></i> : ''}
                                            </Link>
                                            : ''}
                                        {menuItem?.children ?
                                            <ul
                                                className={`sidebar-submenu ${menuItem?.active ? 'menu-open' : ''}`}
                                                style={menuItem?.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                            >
                                                {menuItem?.children.map((childrenItem, index) =>
                                                    <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                                        {(childrenItem?.type === 'sub' && childrenItem?.children.length > 0) ?
                                                            <span className='sidebar_accordion_title' onClick={() => toggletNavActive(childrenItem)} >
                                                                <i className="fa fa-circle"></i>{props.t(childrenItem.title)} <i className="fa fa-angle-right pull-right"></i></span>
                                                            : ''}

                                                        {(childrenItem?.type === 'link') ?
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}${childrenItem?.path}`}
                                                                className={currentRoute === childrenItem?.path ? 'active' : ''}
                                                                onClick={() => toggletNavActive(childrenItem)}
                                                            >
                                                                <i className="fa fa-circle"></i>{props.t(childrenItem?.title)} </Link>
                                                            : ''}
                                                        {childrenItem.children ?
                                                            <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                                {childrenItem.children.map((childrenSubItem, key) =>
                                                                    <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                        {(childrenSubItem.type === 'link') ?
                                                                            <Link
                                                                                to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                                                className={currentRoute === childrenSubItem.path ? 'active' : ''}
                                                                                onClick={() => toggletNavActive(childrenSubItem)}
                                                                            >
                                                                                <i className="fa fa-circle"></i>{props.t(childrenSubItem.title)}</Link>
                                                                            : ''}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            : ''}
                                                    </li>
                                                )}
                                            </ul>
                                            : ''}
                                    </li>
                                )
                            }
                            )
                            :
                            MENUITEMS.map((menuItem, i) =>
                                (((role === "Admin" || role === "Employee") && (menuItem.title !== "Transactions" && menuItem.title !== "Balances" && menuItem.title !== "Questions")) || (role === "Company" && (menuItem.title === "Transactions" || menuItem.title === "Balances" || menuItem.title === "Questions" || menuItem.title === "Card")))
                                    ?
                                    <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                        {(menuItem.sidebartitle) ?
                                            <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                            : ''}
                                        {(menuItem.type === 'sub' && menuItem?.children.length > 0) ?
                                            <span className="sidebar_accordion_title"  onClick={() => toggletNavActive(menuItem)}>
                                                {
                                                    menuItem.title === "Card" ? <IdcardOutlined /> : <menuItem.icon />
                                                }
                                                <span>{props.t(menuItem.title)}</span>
                                                <i className="fa fa-angle-right pull-right"></i>
                                            </span>
                                         
                                            : ''}
                                        {(menuItem.type === 'link') ?
                                            <Link
                                                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                                className={`sidebar-header ${currentRoute === menuItem.path ? 'active' : ''}`}

                                                onClick={() => toggletNavActive(menuItem)}
                                            >
                                                {
                                                    menuItem.title === "Card" ? <IdcardOutlined /> : <menuItem.icon />
                                                }
                                                <span>{props.t(menuItem.title)}</span>
                                                {menuItem.children ?
                                                    <i className="fa fa-angle-right pull-right"></i> : ''}
                                            </Link>
                                            : ''}
                                        {menuItem.children ?
                                            <ul
                                                className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                                style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                            >
                                                {menuItem.children.map((childrenItem, index) =>
                                                    <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                                        {(childrenItem.type === 'sub' && childrenItem?.children.length > 0) ?
                                                            <span className='sidebar_accordion_title' onClick={() => toggletNavActive(childrenItem)} >
                                                                <i className="fa fa-circle"></i>{props.t(childrenItem.title)} <i className="fa fa-angle-right pull-right"></i></span>
                                                            : ''}

                                                        {(childrenItem.type === 'link') ?
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                                                className={currentRoute === childrenItem.path ? 'active' : ''}
                                                                onClick={() => toggletNavActive(childrenItem)}
                                                            >
                                                                <i className="fa fa-circle"></i>{props.t(childrenItem.title)} </Link>
                                                            : ''}
                                                        {childrenItem.children ?
                                                            <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                                {childrenItem.children.map((childrenSubItem, key) =>
                                                                    <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                        {(childrenSubItem.type === 'link') ?
                                                                            <Link
                                                                                to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                                                className={currentRoute === childrenSubItem.path ? 'active' : ''}
                                                                                onClick={() => toggletNavActive(childrenSubItem)}
                                                                            >
                                                                                <i className="fa fa-circle"></i>{props.t(childrenSubItem.title)}</Link>
                                                                            : ''}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            : ''}
                                                    </li>
                                                )}
                                            </ul>
                                            : ''}
                                    </li>
                                    : null
                            )
                        }
                        <li className={`right-arrow ${layout === 'rtl' ? hideRightArrowRTL ? 'd-none' : '' : hideRightArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToRightRTL : scrollToRight}><i className="fa fa-angle-right"></i></li>
                    </ul>
                </div>

            </div>
        </Fragment>
    );
};
const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});

const mapDispatchToProps = (dispatch) => {
    return {
      setDistributorBalance: (distributorBalance) => {
        dispatch({
          type: 'SET_DISTRIBUTOR_BALANCE',
          distributorBalance: distributorBalance
        });
      },
    };
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(translate(Sidebar)));

// export default translate(Sidebar);

