import {
  Home,
  Table,
  BarChart2,
  Users,
  Settings,
  CreditCard,
  List,
  PieChart,
  User,
  DollarSign,
  BookOpen,
  Database,
  Chrome,
  Globe,
  Activity,
  Unlock,
  Lock,
  Briefcase,
  Headphones,
  Pocket,
  Layers,
  
  
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: "/dashboard",
    active: true,
  },
  {
    title: "Reports",
    icon: PieChart,
    type: "link",
    path: "/reports",
    active: true,
  },
  {
    title: "Master",
    icon: List,
    type: "link",
    path: "/master",
    active: true,
  },
  {
    
    title: "User Management",
    icon: User,
    type:"sub",

    children: [
      {
        title: "User",
        icon: User,
        type: "link",
        path: "/user",
        active: true,
      },
      {
        title: "Balance Request",
        icon: DollarSign,
        type: "link",
        path: "/balance-request",
        active: true,
      },
      {
        title: "Profit Wallet Requests",
        icon: Pocket,
        type: "link",
        path: "/profit-wallet-requests",
        active: true,
        company: true,
      },
      {
        title: "Freeze User",
        icon: Lock,
        type: "link",
        path: "/freeze-user",
        active: true,
      },
    ],
  },
  {
    title: "Card & Inventory",
    // title: "Inventory & Support",
    icon: Chrome,
    type:"sub",

    children: [
    { title: "Card",
      icon: Chrome,
      type: "link",
      path: "/card",
      active: true,
      company: true,},
      {
        title: "Inventory",
        icon: Layers,
        type: "link",
        path: "/inventory",
        active: true,
      },
      {
        title: "Inventory Batch",
        icon: List,
        type: "link",
        path: "/inventory-batch-list",
        active: true,
      },
      {
        title: "Ticket Support",
        icon: Headphones,
        type: "link",
        path: "/ticket-support",
        active: true,
      },
    ],
  },
  {
    title: "Role Types",
    icon: Users,
    type:"sub",

    children: [
    {
      title: "Sub Admin",
      icon: Users,
      type: "link",
      path: "/sub-admin",
    },
      {
        title: "Employee",
        icon: Users,
        type: "link",
        path: "/employee",
        active: true,
      },
      {
        title: "Distributor",
        icon: Globe,
        type: "link",
        path: "/distributor",
        active: true,
      },
      {
        title: "Sales",
        icon: BarChart2,
        type: "link",
        path: "/sale",
        active: true,
      },
      {
        title: "Company",
        icon: Briefcase,
        type: "link",
        path: "/company",
        active: true,
      },
    ],
  },
  {
    title: "Settings & Logs",
    icon: Settings,
    type:"sub",

    children: [
    {
    title: "Setting",
    icon: Settings,
    type: "link",
    path: "/setting",
    active: true,
    },
      {
        title: "Activity Logs",
        icon: Activity,
        type: "link",
        path: "/activity-logs",
        active: true,
      },
      {
        title: "Expenses",
        icon: Pocket,
        type: "link",
        path: "/expenses",
        active: true,
      },
    ],
  },
  {
    title: "Balances",
    icon: DollarSign,
    type: "link",
    path: "/balances",
    active: true,
    company: true,
  },
  {
    title: "Questions",
    icon: BookOpen,
    type: "link",
    path: "/company-question",
    active: true,
    company: true,
  },
  {
    title: "Transactions",
    icon: Database,
    type: "link",
    path: "/company-trn",
    active: true,
    company: true,
  },
];






















// export const MENUITEMS = [
//   {
//     title: "Dashboard",
//     icon: Home,
//     type: "link",
//     path: "/dashboard",
//     active: true,
//   },
//   {
//     title: "Reports",
//     icon: PieChart,
//     type: "link",
//     path: "/reports",
//     active: true,
//   },
//   {
//     title: "User",
//     icon: User,
//     type: "link",
//     path: "/user",
//     active: true,
//   },
//   {
//     title: "Master",
//     icon: List,
//     type: "link",
//     path: "/master",
//     active: true,
//   },
//   {
//     title: "Card",
//     icon: Chrome,
//     type: "link",
//     path: "/card",
//     active: true,
//     company: true,
//   },
//   {
//     title: "Balance Request",
//     icon: DollarSign,
//     type: "link",
//     path: "/balance-request",
//     active: true,
//   },
//   {
//     title: "Employee",
//     icon: Users,
//     type: "link",
//     path: "/employee",
//     active: true,
//   },
//   {
//     title: "Sub Admin",
//     icon: Users,
//     type: "link",
//     path: "/sub-admin",
//     active: true,
//   },
//   {
//     title: "Distributor",
//     icon: Globe,
//     type: "link",
//     path: "/distributor",
//     active: true,
//   },
//   {
//     title: "Sales",
//     icon: BarChart2,
//     type: "link",
//     path: "/sale",
//     active: true,
//   },
//   {
//     title: "Company",
//     icon: Briefcase,
//     type: "link",
//     path: "/company",
//     active: true,
//   },
//   {
//     title: "Ticket Support",
//     icon: Headphones,
//     type: "link",
//     path: "/ticket-support",
//     active: true,
//   },
//   {
//     title: "Expenses",
//     icon: Pocket,
//     type: "link",
//     path: "/expenses",
//     active: true,
//   },
//   {
//     title: "Inventory",
//     icon: Layers,
//     type: "link",
//     path: "/inventory",
//     active: true,
//   },
//   {
//     title: "Inventory Batch",
//     icon: List,
//     type: "link",
//     path: "/inventory-batch-list",
//     active: true,
//   },
//   {
//     title: "Profit Wallet Requests",
//     icon: Pocket,
//     type: "link",
//     path: "/profit-wallet-requests",
//     active: true,
//     company: true,
//   },
//   {
//     title: "Setting",
//     icon: Settings,
//     type: "link",
//     path: "/setting",
//     active: true,
//   },
//   {
//     title: "Balances",
//     icon: DollarSign,
//     type: "link",
//     path: "/balances",
//     active: true,
//     company: true,
//   },
//   {
//     title: "Activity Logs",
//     icon: Activity,
//     type: "link",
//     path: "/activity-logs",
//     active: true,
//   },

//   {
//     title: "Freeze User",
//     icon: Lock,
//     type: "link",
//     path: "/freeze-user",
//     active: true,
//   },
//   {
//     title: "Questions",
//     icon: BookOpen,
//     type: "link",
//     path: "/company-question",
//     active: true,
//     company: true,
//   },
//   {
//     title: "Transactions",
//     icon: Database,
//     type: "link",
//     path: "/company-trn",
//     active: true,
//     company: true,
//   },

//   // {
//   //     title: 'Transactions', icon: CreditCard, type: 'link', path: '/transaction', active: true
//   // },
//   // {
//   //     title: 'Dashboard', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
//   //         { path: '/dashboard/default', title: 'Default', type: 'link' },
//   //         { path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' },
//   //         { path: '/dashboard/university', title: 'University', type: 'link' },
//   //         { path: '/dashboard/crypto', title: 'Crypto', type: 'link' },
//   //         { path: '/dashboard/project', title: 'Project', type: 'link' }
//   //     ]
//   // },
//   // {
//   //     title: 'Support Ticket', icon: Headphones, type: 'link', path: '/support-ticket/supportTicket', active: false
//   // },
//   // {
//   //     path: '/sample/samplepage', title: 'Sample Page', icon: File, type: 'link', active: false
//   // },
// ];

export const distributorMenuItems = [
  {
    title: "User",
    icon: Users,
    type: "link",
    path: "/user",
    active: true,
  },
  {
    title: "Balance Request",
    icon: DollarSign,
    type: "link",
    path: "/balance-request",
    active: true,
  },
];
