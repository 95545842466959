import React, { Component } from "react";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { financialReportAPI } from "../../Graphs/Report/financialReport";
import { formatePrice } from "../../utils/general";
import TableWrap from "../common/Table";
import { callGetApiToServer } from "../../Graphs/callApi";
import { Table } from "antd";

class FinancialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      financialData: null,
      isTableOpen: false,
    };
  }

  async componentDidMount() {
    try {
      const { authToken, loginUserData, history } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      const payload = {
        city: city
      }

      let financialReport = await financialReportAPI(authToken, payload);
      this.setState({
        isLoading: true,
        financialData: financialReport.data,
      });
      if (financialReport.status == 200) {
        this.setState({
          isLoading: false,
          isTableOpen: true,
        });
      }else if (financialReport.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw financialReport && financialReport.message
          ? financialReport.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  columns = [
    {
      title: "",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "data",
    },
    {
      title: "",
      dataIndex: "balance",
    },
  ];

  render() {
    const { financialData, isTableOpen } = this.state;
    let allData = [];

    if (financialData) {
      const userCoin = financialData?.user_coin?.map((item) => {
        return {
          name: "User Coin",
          data: item.coin.name,
          balance: `${formatePrice(item?.total_amount)}`,
        };
      });

      const data = [
        {
          name: "Distributor Coin",
          data: "-",
          balance: `${formatePrice(financialData?.distributor_coin)}`,
        },
        {
          name: "Salesman Coin",
          data: "-",
          balance: `${formatePrice(financialData?.salesman_coin)}`,
        },
        {
          name: "Salesman Collection",
          data: "-",
          balance: `${formatePrice(financialData?.saleman_collected)}`,
        },
        {
          name: "Salesman Outstanding	",
          data: "-",
          balance: `${formatePrice(financialData?.salesman_outstanding)} LYD`,
        },
        {
          name: "User Outstanding	",
          data: "-",
          balance: `${formatePrice(financialData?.user_outstanding)} LYD`,
        },
        {
          name: "Total Sold Card Amount",
          data: "-",
          balance: `${formatePrice(financialData?.total_sold_card_amount)} LYD`,
        },
        {
          name: "Total Sold Card Count",
          data: "-",
          balance: `${financialData?.total_sold_card_count}`,
        },
        
        ...(!this.props.loginUserData.city?[
          {
            name: "Available Admin Inventory",
            data: "-",
            balance: `${financialData.available_admin_inventory}`,
          }
        ]:[]),
        
        ...(!this.props.loginUserData.city?[
          {
            name: "Available Admin Inventory Cost Amount",
            data: "-",
            balance: `${formatePrice(financialData.available_admin_inventory_cost)}`,
          }
        ]:[]),
        {
          name: "Available Sub Admin Inventory",
          data: "-",
          balance: `${financialData.available_sub_admin_inventory}`,
        },
        {
          name: "Available Sub Admin Inventory Cost Amount",
          data: "-",
          balance: `${formatePrice(financialData.available_sub_admin_inventory_cost)}`,
        },
        ...(!this.props.loginUserData.city?[
          {
            name: "Available Company Inventory",
            data: "-",
            balance: `${financialData.available_company_inventory}`,
          },
        ]:[]),
        ...(!this.props.loginUserData.city ?[
          {
            name: "Available Company Inventory Cost Amount",
            data: "-",
            balance: `${formatePrice(financialData.available_company_inventory_cost)}`,
          },
        ]:[]),
        
     
      ];
     
      allData = [...userCoin, ...data];
    }

    return (
      <>
        {isTableOpen && allData.length > 0 && (
          <Table
            style={{ width: "600px" }}
            rowKey={(allData) => allData.balance }
            loading={this.isLoading}
            columns={this.columns}
            data={allData}
            dataSource={allData}
            pagination={false}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(FinancialReport));
