import React, { Component } from "react";
import { Button, DatePicker, Table, Select } from "antd";
import { Formik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { cardBuyReportAPI } from "../../Graphs/Report/userCardBuyReport";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { formatePrice } from "../../utils/general";

const { RangePicker } = DatePicker;

class CardBuyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cityData: [],
      startDate: null,
      endDate: null,
      isLoading: false,
      isTableOpen: false,
      adminTotalAmount: 0,
      adminTotalCount: 0,
      subAdminTotalAmount: 0,
      subAdminTotalCount: 0,
      companyTotalAmount: 0,
      companyTotalCount: 0,
      mintApiTotalAmount: 0,
      mintApiTotalCount: 0,
      likeApiTotalAmount: 0,
      likeApiTotalCount: 0,
    };
  }

  resetExpensesReportTable = () => {
    this.setState({
      data: [],
      isTableOpen: false,
      adminTotalAmount: 0,
      adminTotalCount: 0,
      subAdminTotalAmount: 0,
      subAdminTotalCount: 0,
      companyTotalAmount: 0,
      companyTotalCount: 0,
      mintApiTotalAmount: 0,
      mintApiTotalCount: 0,
      likeApiTotalAmount: 0,
      likeApiTotalCount: 0,
    });
  };
  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetExpensesReportTable();
      return;
    }
    this.setState({
      startDate: date[0]?.format("YYYY-MM-DD"),
      endDate: date[1]?.format("YYYY-MM-DD"),
    });
  };

  fetchCityList = async () => {
    try {
      const { authToken, history } = this.props;

      this.setState({ isLoading: true });
      const response = await getCityListAPI(authToken);

      if (response.status === 200) {
        const cityOptions = response.data.map((city) => ({
          label: city.name,
          value: city.id,
        }));

        cityOptions.unshift({ label: "All", value: "" });

        this.setState({ cityData: cityOptions });
      } else if (response.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw response.message || "Error fetching city data.";
      }
    } catch (error) {
      alertError("CITY LIST", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchCityList();
  }

  netCardBuyReportList = async (startDate, endDate, city) => {
    try {
      const { authToken } = this.props;

      // If city is "All" (empty string), pass "" in the API payload
      const payload = { startDate, endDate, city };

      this.setState({ isLoading: true });
      const response = await cardBuyReportAPI(authToken, payload);

      if (response.status === 200) {
        let adminTotalAmount = 0;
        let adminTotalCount = 0;
        let subAdminTotalAmount = 0;
        let subAdminTotalCount = 0;
        let companyTotalAmount = 0;
        let companyTotalCount = 0;
        let mintApiTotalAmount = 0;
        let mintApiTotalCount = 0;
        let likeApiTotalAmount = 0;
        let likeApiTotalCount = 0;

        response.data.forEach((element) => {
          adminTotalAmount += Number(element?.admin_total_amount ?? 0);
          adminTotalCount += Number(element?.admin_count ?? 0);
          subAdminTotalAmount += Number(element?.sub_admin_total_amount ?? 0);
          subAdminTotalCount += Number(element?.sub_admin_count ?? 0);
          companyTotalAmount += Number(element?.company_total_amount ?? 0);
          companyTotalCount += Number(element?.company_count ?? 0);
          mintApiTotalAmount += Number(element?.mint_api_total_amount ?? 0);
          mintApiTotalCount += Number(element?.mint_api_count ?? 0);
          likeApiTotalAmount += Number(element?.like_api_total_amount ?? 0);
          likeApiTotalCount += Number(element?.like_api_count ?? 0);
        });

        this.setState({
          adminTotalAmount: adminTotalAmount,
          adminTotalCount: adminTotalCount,
          subAdminTotalAmount: subAdminTotalAmount,
          subAdminTotalCount: subAdminTotalCount,
          companyTotalAmount: companyTotalAmount,
          companyTotalCount: companyTotalCount,
          mintApiTotalAmount: mintApiTotalAmount,
          mintApiTotalCount: mintApiTotalCount,
          likeApiTotalAmount: likeApiTotalAmount,
          likeApiTotalCount: likeApiTotalCount,
          data: response.data,
          isTableOpen: true,
        });
      } else {
        throw response.message || "Error fetching report data.";
      }
    } catch (error) {
      alertError("REPORT", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  columns = [
    {
      title: <strong>Sr. No.</strong>,
      key: "serial_number",
      width: 150,
      render: (text, item, index) => <span>{index + 1}</span>,
    },
    {
      title: <strong>Card Name</strong>,
      key: "card_name",
      width: 150,
      render: (text, item) => item.card?.name || "-",
    },
    {
      title: <strong>City Name</strong>,
      key: "city_name",
      width: 150,
      render: (text, item) => item.user_city?.name || "-",
    },
    {
      title: (
        <>
          <strong>
            {" "}
            From Sub-Admin <br />
            Count(Amount)
          </strong>
        </>
      ),
      key: "sub_admin_count",
      width: 150,
      render: (text, item) => (
        <>
          {item.sub_admin_count ?? 0} (
          <strong>{formatePrice(item.sub_admin_total_amount) ?? 0}</strong>)
        </>
      ),
    },
    {
      title: (
        <strong>
          From Admin <br /> Count (Amount)
        </strong>
      ),
      key: "admin_count",
      width: 150,
      render: (text, item) => (
        <>
          {item.admin_count ?? 0} (
          <strong>{formatePrice(item.admin_total_amount) ?? 0}</strong>)
        </>
      ),
    },
    {
      title: (
        <strong>
          From Company <br /> Count (Amount)
        </strong>
      ),
      key: "company_count",
      width: 150,
      render: (text, item) => (
        <>
          {item.company_count ?? 0} (
          <strong>{formatePrice(item.company_total_amount) ?? 0}</strong>)
        </>
      ),
    },
    {
      title: (
        <strong>
          From Mint API <br /> Count (Amount)
        </strong>
      ),
      key: "mint_api_count",
      width: 150,
      render: (text, item) => (
        <>
          {item.mint_api_count ?? 0} (
          <strong>{formatePrice(item.mint_api_total_amount) ?? 0}</strong>)
        </>
      ),
    },

    {
      title: (
        <strong>
          From Like API <br />
          Count (Amount)
        </strong>
      ),
      key: "like_api_count",
      width: 150,
      render: (text, item) => (
        <>
          {item.like_api_count ?? 0} (
          <strong>{formatePrice(item.like_api_total_amount) ?? 0}</strong>)
        </>
      ),
    },
  ];

  render() {
    const { isTableOpen, data, isLoading, startDate } = this.state;
    

    return (
      <>
        <div className="sub-admin_card_buy_report">
          <Formik initialValues={{ date: "" }} onSubmit={() => {}}>
            {({ handleSubmit }) => (
              <div style={{ display: "flex", gap: "10px", margin: "20px 0" }}>
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={this.onChangeDate}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </div>
            )}
          </Formik>
          <Formik
            initialValues={{
              city: "",
            }}
            onSubmit={(values) => {
              this.resetExpensesReportTable();
              const { startDate, endDate } = this.state;
              const { city } = values;

              if (!startDate || !endDate) {
                return alertError(
                  "REPORT",
                  "Please select a valid date range."
                );
              }

              this.netCardBuyReportList(startDate, endDate, city);
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "10px",
                  marginBottom: "20px",
                  justifyContent: "left",
                }}
              >
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select City"
                  value={values.city}
                  onChange={(value) => setFieldValue("city", value)}
                  options={this.state.cityData}
                />

                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!this.state.startDate}
                >
                  Filter
                </Button>
              </div>
            )}
          </Formik>
        </div>

        {/* Table */}
        {isTableOpen && (
          <Table
            dataSource={data}
            columns={this.columns}
            loading={isLoading}
            rowKey={(data) => data.card_id}
            pagination={false}
            scroll={{ x: 1200 }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row
                    style={{
                      fontWeight: 700,
                      position: "sticky",
                      bottom: "0",
                      zIndex: 1,
                      background: "white",
                    }}
                  >
                    <Table.Summary.Cell
                      style={{ fontWeight: 700, fixed: true }}
                    >
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {this.state.subAdminTotalCount} (
                      {formatePrice(this.state.subAdminTotalAmount)})
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {this.state.adminTotalCount} (
                      {formatePrice(this.state.adminTotalAmount)})
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {this.state.companyTotalCount} (
                      {formatePrice(this.state.companyTotalAmount)})
                    </Table.Summary.Cell>

                    <Table.Summary.Cell>
                      {this.state.mintApiTotalCount} (
                      {formatePrice(this.state.mintApiTotalAmount)})
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {this.state.likeApiTotalCount} (
                      {formatePrice(this.state.likeApiTotalAmount)})
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(CardBuyReport));
